import { on, parents, ready } from "./utils";
import {create, registerPlugin} from "filepond";
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

ready(() => {
    if (document.querySelector('.gallery') === null) {
        return;
    }

    // Checkbox activate
    on('.gallery', 'change', 'input[type=checkbox]', (event) => {
        if (event.target.checked) {
            parents(event.target, '.gallery-item')[0].classList.add('active');
        } else {
            parents(event.target, '.gallery-item')[0].classList.remove('active');
        }
    });

    // remove item
    on('.gallery', 'click', '.gallery-item-remove', (event) => {
        // prevent focus
        event.preventDefault();
        event.stopPropagation();
        const item = parents(event.target, '.gallery-item')[0];

        const url = event.target.dataset['url'];
        console.log(url);

        fetch(url, {
            method: 'DELETE',
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                item.addEventListener('transitionend', (event) => {
                    event.target.remove();
                });

                item.style.opacity = 0;
            } else {
                // error
                console.error(response);
            }
        });

        return false;
    });

    document.getElementById('gallery-remove-items')?.addEventListener('click', (event) => {
        const checks = [].slice.call(document.querySelectorAll('.form-check-input'))
            .filter(element => element.checked);
        if (checks.length === 0) {
            return;
        }

        const ids = checks
            .map(element => element.getAttribute('value'));
        const items = parents(checks, '.gallery-item');

        fetch(event.target.dataset['url'], {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({images: ids}),
        }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                items.map((element) => {
                    element.addEventListener('transitionend', (event) => {
                        event.target.remove();
                    });

                    element.style.opacity = 0;
                });
            } else {
                // error
                console.error(response);
            }
        });
    });

    // toggle all
    document.getElementById('gallery-toggle-items')?.addEventListener('click', () => {
        document.querySelectorAll('.gallery-item').forEach(item => {
            /** @type {HTMLInputElement} */
            const checkbox = item.querySelector('input[type=checkbox]');
            if (checkbox.checked) {
                item.classList.remove('active');
                checkbox.checked = false;
            } else {
                item.classList.add('active');
                checkbox.checked = true;
            }
        });
    });

    const filepond = document.querySelector('.gallery-filepond');
    if (filepond !== null) {
        const url = filepond.dataset['url'];
        console.log(url);

        registerPlugin(FilePondPluginImagePreview);

        // Remove submit button
        filepond.nextElementSibling.remove();
        // noinspection JSCheckFunctionSignatures
        const filepondInstance = create(filepond, {
            credits: false,
            storeAsFile: true,
            server: {
                // url,
                // headers: {
                //     'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                //     // Set this header for correct request type checking
                //     'X-Requested-With': 'XMLHttpRequest',
                // },
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                    // fieldName is the name of the input field
                    // file is the actual file object to send
                    const formData = new FormData();
                    // noinspection JSCheckFunctionSignatures
                    formData.append(fieldName, file, file.name);

                    const request = new XMLHttpRequest();
                    request.open('POST', url);
                    request.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
                    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

                    // Should call the progress method to update the progress to 100% before calling load
                    // Setting computable to false switches the loading indicator to infinite mode
                    request.upload.onprogress = (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    };

                    // Should call the load method when done and pass the returned server file id
                    // this server file id is then used later on when reverting or restoring a file
                    // so your server knows which file to return without exposing that info to the client
                    request.onload = function () {
                        if (request.status >= 200 && request.status < 300) {
                            // the load method accepts either a string (id) or an object
                            load(request.responseText);
                        } else {
                            // Can call the error method if something is wrong, should exit after
                            error('oh no');
                        }
                    };

                    request.send(formData);

                    // Should expose an abort method so the request can be cancelled
                    return {
                        abort: () => {
                            // This function is entered if the user has tapped the cancel button
                            request.abort();

                            // Let FilePond know the request has been cancelled
                            abort();
                        },
                    };
                },
            },
            instantUpload: true,
            allowRevert: false,
            allowRemove: false,
            allowReplace: false,
            onprocessfile: (error, file) => {
                console.log(file);
                const image = JSON.parse(file.serverId);
                /** @type {HTMLTemplateElement} template */
                const template = document.getElementById('gallery-item-template');
                // noinspection JSValidateTypes
                /** @type {DocumentFragment} clone */
                const cloned = template.content.cloneNode(true);
                const item = cloned.firstElementChild;
                //const item = cloned.querySelector('.gallery-item');

                item.setAttribute('href', `${image['path']}/original/${image['image']}`);
                item.dataset['id'] = image['id'];
                item.querySelector('.image > img').setAttribute('src', `${image['path']}/320/${image['image']}`);
                item.querySelector('.gallery-item-controls .form-check-input').setAttribute('value', image['id']);
                item.querySelector('.gallery-item-remove').dataset['url'] = item.querySelector('.gallery-item-remove').dataset['url'].replace(':id', image['id']);
                item.style.opacity = 0;

                document.querySelector('.gallery').appendChild(cloned);
                setTimeout(() => {
                    item.style.opacity = 1;
                }, 200)
                filepondInstance.removeFile(file);
            },
        });
    }
});
