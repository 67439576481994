import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tooltip';
import Popover from 'bootstrap/js/dist/popover';
import Modal from 'bootstrap/js/dist/modal';
import Tab from 'bootstrap/js/dist/tab';
import { create, registerPlugin, FilePondOptions } from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {ready, on, parents} from './utils';
import './gallery';

ready(() => {
    // Function-level strict mode syntax
    'use strict';

    initializeFilePond();
    initializePopovers();

    initializeConfirmationModal();
    initializeFiltersForm();
    initializeProductBuilder();

    // document.getElementById('links').onclick = function (event) {
    //     event = event || window.event;
    //     var target = event.target || event.srcElement;
    //     var link = target.src ? target.parentNode : target;
    //     var options = {index: link, event: event,onclosed: function(){
    //             setTimeout(function(){
    //                 $("body").css("overflow","");
    //             },200);
    //         }};
    //     var links = this.getElementsByTagName('a');
    //     blueimp.Gallery(links, options);
    // };
});

function initializeProductBuilder() {
    // exit if not on product builder page
    if (document.querySelector('div.tab-pane#variants') === null) {
        return;
    }

    console.debug('Initializing product builder...');

    // Focus on first tab with error if any
    const tabsWithInvalidData = parents(document.querySelectorAll('.is-invalid'), '.tab-pane');
    if (tabsWithInvalidData.length > 0) {
        const firstInvalidTab = tabsWithInvalidData[0];
        const trigger = document.querySelector(`#${firstInvalidTab.id}-tab`);
        Tab.getOrCreateInstance(trigger).show();
    }

    const optionsTable = document.querySelector('div.options-table');
    const variantsTable = document.querySelector('div.variants-table');

    // enable variants toggle
    document.querySelector('input.enable-variants').addEventListener('change', () => optionsTable.classList.toggle('d-none'));

    // option picker, load option values
    on(optionsTable, 'change', 'select.option-picker', (event) => {
        // populate option values
        /** @type {HTMLSelectElement} */
        const optionValuePicker = event.target.parentElement.nextElementSibling.querySelector('.option-value-picker');
        event.target.classList.remove('is-invalid');
        optionValuePicker.classList.remove('is-invalid');
        optionValuePicker.setAttribute('disabled', '');
        fetch(`/api/option/${event.target.value}/values`)
            .then(response => response.json())
            .then(data => {
                // https://developer.mozilla.org/en-US/docs/Web/API/HTMLOptionsCollection#properties
                optionValuePicker.length = 1;
                optionValuePicker.selectedIndex = 0;
                optionValuePicker.options[0].innerText = 'Select option';
                // fill options
                for (const key in data) {
                    const option = document.createElement('option');
                    option.value = key;
                    option.innerHTML = data[key];
                    optionValuePicker.append(option);
                }
                optionValuePicker.removeAttribute('disabled');
            });
    });

    // value picker change event
    on(optionsTable, 'change', 'select.option-value-picker', (event) => {
        event.target.classList.remove('is-invalid');
    });

    // add option
    document.querySelector('button.add-option').addEventListener('click', () => {
        /** @type {HTMLTemplateElement} template */
        const template = document.querySelector('#option-picker-row');
        // noinspection JSValidateTypes
        /** @type {DocumentFragment} clone */
        const cloned = template.content.cloneNode(true);
        optionsTable.querySelector('tbody').appendChild(cloned);
    });

    // remove option
    on(optionsTable, 'click', 'button.remove-option', (event) => {
        const row = event.target.parentElement.parentElement;
        row.remove();
    });

    // add variant
    document.querySelector('button.add-variant').addEventListener('click', (event) => {
        event.preventDefault();

        let isValid = true;
        let options = [];

        const rows = document.querySelectorAll('.options-table tbody > tr');
        rows.forEach((row) => {
            /** @type {HTMLSelectElement} */
            const option = row.querySelector('.option-picker');
            /** @type {HTMLSelectElement} */
            const value = row.querySelector('.option-value-picker');

            if (option.selectedIndex === 0 || value.selectedIndex === 0) {
                option.selectedIndex === 0 && option.classList.add('is-invalid');
                value.selectedIndex === 0 && value.classList.add('is-invalid');
                isValid = false;
                return;
            }

            options.push({
                id: option.value,
                name: option.options[option.selectedIndex].textContent,
                value: value.value,
                value_name: value.options[value.selectedIndex].textContent,
            });
        });

        // if validation passed, reset form and add variant
        if (isValid) {
            rows.forEach((row) => {
                if (row.matches('tbody > tr:first-child')) {
                    row.querySelector('.option-picker').selectedIndex = 0;
                    /** @type {HTMLSelectElement} */
                    const value = row.querySelector('.option-value-picker');
                    value.selectedIndex = 0;
                    value.length = 1;
                    value.setAttribute('disabled', '');
                } else {
                    row.remove();
                }
            });

            // filter out duplicate options, keep last
            options = [...new Map(options.map(item => [item['id'], item])).values()];

            variantsTable.classList.remove('d-none');
            const tbody = variantsTable.querySelector('tbody');
            const id = tbody.childElementCount;

            /** @type {HTMLTemplateElement} template */
            const template = document.querySelector('#variants-row');
            // noinspection JSValidateTypes
            /** @type {DocumentFragment} clone */
            const cloned = template.content.cloneNode(true);
            cloned.firstElementChild.innerHTML = cloned.firstElementChild.innerHTML.replace(/:id/g, id.toString());
            cloned.querySelector('.variant-attributes').innerHTML = options
                .sort((a, b) => a.id - b.id)
                .reduce((html, option, key) => {
                    html = `${html}<input type="hidden" name="variants[${id}][options][${key}][id]" value="${option.id}">`;
                    html = `${html}<input type="hidden" name="variants[${id}][options][${key}][option][value_id]" value="${option.value}">`;
                    html = `${html}<input type="hidden" name="variants[${id}][options][${key}][name]" value="${option.name}">`;
                    html = `${html}<input type="hidden" name="variants[${id}][options][${key}][translation][display_name]" value="${option.value_name}">`;
                    html = `${html}<small class="d-block"><strong>${option.name}:</strong> ${option.value_name}</small>`;

                    return html;
                }, '');

            tbody.appendChild(cloned);
            tbody.querySelector('input:first-child').focus();
        }
    });

    // remove variant
    on(variantsTable, 'click', 'button.remove-variant', (event) => {
        const row = event.target.parentElement.parentElement;
        row.remove();
        if (variantsTable.querySelector('tbody > tr') === null) {
            variantsTable.classList.add('d-none');
        }
    });
}

function initializeFiltersForm() {
    // exit if filters form not present
    /** @type {HTMLFormElement} */
    const form = document.getElementById('filters-form');
    if (form === null) {
        return;
    }
    console.debug('Initializing filters form...');

    form.addEventListener('submit', function() {
        // Disable empty fields to
        /** @type {HTMLInputElement[]|HTMLSelectElement[]} **/
        const elements = [].slice.call(form.elements);
        elements.filter((element) => element.value.length === 0).forEach((element) => element.setAttribute('disabled', ''));
    });
    form.addEventListener('reset', function() {
        // form.reset();
        const elements = [].slice.call(form.elements);
        elements.forEach((element) => element.value = '' & element.setAttribute('disabled', ''));
        if (form.requestSubmit) {
            form.requestSubmit();
        } else {
            form.submit();
        }
    });
}

function initializeConfirmationModal() {
    // exit if confirmation modal not present
    const modalEl = document.querySelector('.modal-confirm');
    if (modalEl === null) {
        return;
    }
    console.debug('Initializing confirmation modal...');

    /** @type {Modal} */
    const modal = new Modal(modalEl);
    const modalTriggerListener = function (event) {
        event.preventDefault();
        /** @type {HTMLAnchorElement|HTMLFormElement} */
        const target = event.currentTarget;
        modalEl.querySelector('.modal-body').textContent = target.dataset['confirm'];
        const confirmBtn = modalEl.querySelector('.modal-confirm');
        const confirm = function() {
            if (target instanceof HTMLAnchorElement) {
                target.removeEventListener('click', modalTriggerListener);
                target.click();
            } else if (target instanceof HTMLFormElement) {
                target.removeEventListener('submit', modalTriggerListener);
                target.submit();
            }
        };

        confirmBtn.addEventListener('click', confirm);
        modalEl.addEventListener('hidden.bs.modal', function() {
            confirmBtn.removeEventListener('click', confirm);
        });
        modal.show();
    };
    const confirmTriggerList = [].slice.call(document.querySelectorAll('.needs-confirmation'));
    confirmTriggerList.forEach(function (confirmTriggerEl) {
        if (confirmTriggerEl instanceof HTMLAnchorElement) {
            confirmTriggerEl.addEventListener('click', modalTriggerListener);
        } else if (confirmTriggerEl instanceof HTMLFormElement) {
            confirmTriggerEl.addEventListener('submit', modalTriggerListener);
        }
    });
}

function initializePopovers() {
    console.debug('Initializing popovers...');

    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.forEach(function (popoverTriggerEl) {
        new Popover(popoverTriggerEl);
    });
}

function initializeFilePond() {
    console.debug('Initializing FilePond...');

    document.querySelectorAll('fieldset.filepond').forEach((fieldset) => {
        registerPlugin(FilePondPluginImagePreview);

        // noinspection JSValidateTypes
        /** @type FilePondOptions */
        const options = {
            credits: false,
            storeAsFile: true,
        };


        create(fieldset, options);
    });
}

// GRID JAVASCRIPT
(function () {
    'use strict'

    if (document.querySelector('.variant-options') !== null) {
        /** @type {HTMLSelectElement} */
        const valueSelector = document.querySelector('#value_id');
        // We are on dashboard, load data
        document.querySelector('#option_id').addEventListener('change', function (event) {
            valueSelector.setAttribute('disabled', '');
            fetch(`/api/option/${event.target.value}/values`)
                .then(response => response.json())
                .then(data => {
                    valueSelector.options[0].innerText = 'Select Option';
                    const length = valueSelector.options.length - 1;
                    for (let i = 1; i <= length; i++) {
                        valueSelector.options[1].remove();
                        valueSelector.selectedIndex = 0;
                    }
                    for (const key in data) {
                        const option = document.createElement('option');
                        option.value = key;
                        option.innerHTML = data[key];
                        valueSelector.append(option);
                    }
                    valueSelector.removeAttribute('disabled');
                });
        });
    }

    // Grid controls
    const controlsTriggerList = [].slice.call(document.querySelectorAll('.grid-controls select'));
    controlsTriggerList.forEach(function (controlsTriggerEl) {
        controlsTriggerEl.addEventListener('change', function () {
            controlsTriggerEl.form.submit();
        });
    });

    // Grid filters
    /** @type {HTMLFormElement} */
    const filtersForm = document.querySelector('.grid-filters-form');
    if (filtersForm !== null) {
        console.log(`Initializing grid filters...`);
        document.querySelector('.grid-filters .btn-filters-apply').addEventListener('click', function () {
            submitFiltersForm();
        });
        document.querySelector('.grid-filters .btn-filters-reset').addEventListener('click', function () {
            filtersForm.submit();
        });
        const filterFormTriggerList = [].slice.call(document.querySelectorAll('.grid-filters input'));
        filterFormTriggerList.forEach(function (filterFormTriggerEl) {
            filterFormTriggerEl.addEventListener('keypress', function(event) {
                if (event.key === 'Enter') {
                    submitFiltersForm();
                }
            });
        });

        function submitFiltersForm() {
            const inputs = document.querySelectorAll('.grid-filters input, .grid-filters select');
            inputs.forEach(function (input) {
                let duplicate = null;
                if (input instanceof HTMLInputElement) {
                    const value = input.value;
                    if (value !== '') {
                        duplicate = input.cloneNode(true);
                        duplicate.setAttribute('type', 'hidden');
                        filtersForm.appendChild(duplicate);
                    }
                } else if (input instanceof HTMLSelectElement) {
                    const value = input.options[input.selectedIndex].value;
                    if (value !== '') {
                        /** @type {HTMLInputElement} */
                        duplicate = document.createElement('input');
                        duplicate.name = input.name;
                        duplicate.value = value;
                        duplicate.setAttribute('type', 'hidden');
                        filtersForm.appendChild(duplicate);
                    }
                }

                filtersForm.submit();
            });
        }
    }

    // Confirm modals

})();
